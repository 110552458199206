export const HEADLINE_TEXT_CONFIGURATIONS = Object.freeze({
  mobile: {
    firstLine: 'Connecting families',
    secondLine: 'with quality,',
    thirdLine: 'local caregivers',
  },
  tabletOrDesktop: {
    firstLine: 'Connecting families with',
    secondLine: 'quality, local caregivers',
    thirdLine: '',
  },
});

export const HOW_IT_WORKS_INSTRUCTIONS = [
  {
    stepNumber: 1,
    headline: 'Tell us what you need',
    subHeadline: 'Share your care needs and start reviewing profiles.',
  },
  {
    stepNumber: 2,
    headline: 'Choose your caregiver',
    subHeadline: "Start a conversation and hire the caregiver that's right for you.",
  },
  {
    stepNumber: 3,
    headline: 'Get 24/7 support',
    subHeadline: 'Chat with a live agent in an average of 1 minute or less.',
  },
] as const;
