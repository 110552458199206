import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import ZipCodePanel from '@/components/shared/ZipCodePanel/ZipCodePanel';
import { VERTICALS_NAMES, LAYOUTS } from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';
import useResponsive from '@/components/hooks/useResponsive';
import { handleUserNeedSelected } from '@/components/shared/HomePageTopNavigation/utils';
import { Location, SxClassProps } from '@/types';

import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';
import { HEADLINE_TEXT_CONFIGURATIONS } from '@/components/pages/visitorHome/VisitorHomeRebrandLayout/constants';

import heroImage from '@/../public/rebranding/hero-large.webp';
import heroImageMobile from '@/../public/rebranding/hero-mobile.webp';

const classes: SxClassProps = {
  container: {
    width: '100%',
  },
  imageContainer: (theme) => ({
    padding: {
      xs: theme.spacing(11, 0, 0, 3),
      sm: theme.spacing(5, 0, 0, 4),
      lg: theme.spacing(5, 0, 0, 10),
    },
    height: {
      xs: '246px',
      sm: '544px',
      md: '564px',
    },
    backgroundImage: {
      xs: `url(${heroImageMobile.src})`,
      sm: `url(${heroImage.src})`,
    },
    backgroundPosition: {
      xs: 'center',
      sm: '60%',
      md: 'center',
    },

    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: {
      xs: '0 0 70px 70px',
      sm: '0 0 120px 120px',
    },
    overflow: 'hidden',
  }),
  content: {
    maxWidth: {
      xs: '100%',
      sm: '1280px',
    },
    margin: '0 auto',
  },
  headerCopyLine: (theme) => ({
    fontSize: {
      xs: '28px',
      sm: '36px',
      md: '48px',
    },
    lineHeight: {
      xs: '39px',
      sm: '42px',
      md: '56px',
    },
    backgroundColor: 'rgba(33, 63, 95, 0.9)',
    width: 'fit-content',
    color: 'white',
    padding: {
      xs: theme.spacing(0, 1),
      sm: `14px ${theme.spacing(3)}`,
    },
    borderRadius: '12px',
    marginBottom: {
      xs: '4px',
      sm: 1,
    },
  }),
  zipCodePanel: {
    marginTop: {
      xs: 0,
      sm: 4,
    },
    padding: {
      xs: 3,
    },
    width: {
      xs: '100%',
      sm: '368px',
      md: '400px',
    },
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    border: {
      xs: 'none',
      sm: '1px solid #D1D1D1',
    },
    borderRadius: '16px',
  },
};

const preloadImage = (url: string) => {
  const img = new Image();
  img.src = url;
};

const getHeaderCopy = (isTablet: boolean, isDesktop: boolean) => {
  const { mobile, tabletOrDesktop } = HEADLINE_TEXT_CONFIGURATIONS;
  return isTablet || isDesktop ? tabletOrDesktop : mobile;
};

function Hero() {
  const { vhpTriageProviderVertical } = useTestFlags();
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const routeProviderToTriagePage = vhpTriageProviderVertical?.value === 2;

  const headerCopy = getHeaderCopy(isTablet, isDesktop);

  useEffect(() => {
    if (isMobile) {
      preloadImage(heroImageMobile.src);
    } else {
      preloadImage(heroImage.src);
    }
  }, [isMobile]);

  const onZipCodeSelected = (locationEntered: Location | undefined, isProvider: boolean) => {
    // define a user needs for all users
    const userNeed = isProvider ? 'Find a job' : 'Find care';

    let zipData = {};
    if (locationEntered) {
      const { city, state, verifiedApiZipcode: zip } = locationEntered;
      zipData = {
        zip,
        city,
        state,
      };
    }

    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        cta_location: CtaLocation.VHP_HERO,
        enrollment_initiated: true,
        vertical: VERTICALS_NAMES.unknown,
        user_need: userNeed,
        cta_text: CtaText.SEARCH,
        ...zipData,
      },
    });

    handleUserNeedSelected(isProvider, isDesktop, routeProviderToTriagePage);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ZipPanel = () => (
    <Box sx={classes.zipCodePanel}>
      <ZipCodePanel
        onZipCodeSelected={onZipCodeSelected}
        withoutUserTypeSelection={false}
        transparent
        panelCtaText={CtaText.SEARCH}
        layout={LAYOUTS.rebranding}
        iconLeft
      />
    </Box>
  );

  return (
    <Box sx={classes.container}>
      <Box sx={classes.imageContainer}>
        <Box sx={classes.content}>
          <Typography variant="h2" sx={classes.headerCopyLine}>
            {headerCopy.firstLine}
          </Typography>

          <Typography variant="h2" sx={classes.headerCopyLine}>
            {headerCopy.secondLine}
          </Typography>

          {isMobile && (
            <Typography variant="h2" sx={classes.headerCopyLine}>
              {headerCopy.thirdLine}
            </Typography>
          )}

          {!isMobile && <ZipPanel />}
        </Box>
      </Box>

      {isMobile && <ZipPanel />}
    </Box>
  );
}

export default Hero;
