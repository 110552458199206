import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import { IterableEmbeddedProvider, useOnJwtRequest } from '@care/iterable-display';

import logger from '@/lib/clientLogger';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import {
  CZEN_SESSION_COOKIE_KEY,
  CZEN_VISITOR_COOKIE_KEY,
  LAYOUTS,
  GTM_SLOTS,
  VerticalSelection,
  UNKNOWN_VERTICAL,
  CLIENT_FEATURE_FLAGS,
} from '@/constants';
import IterableBanner from '@/components/shared/IterableBanner/IterableBanner';
import PromocodeRibbon from '@/components/shared/PromocodeRibbon/PromocodeRibbon';
import usePromocodeRibbon from '@/components/hooks/usePromocodeRibbon';
import useResponsive from '@/components/hooks/useResponsive';
import useRxCodes from '@/components/hooks/useRxCodes';
import HeaderWithSEOMegaNav from '@/components/shared/HeaderWithSEOMegaNav';
import withLazyLoad from '@/utilities/withLazyLoad';
import { AnalyticsEventName, ScreenName } from '@/types/enums';
import useTestFlags from '@/components/hooks/useTestFlags';
import Hero from './Hero/Hero';
import VerticalSelectionBlock from './VerticalSelection/VerticalSelection';

const {
  publicRuntimeConfig: {
    ITERABLE_MFE_API_KEY,
    ITERABLE_MFE_PACKAGE_NAME,
    ITERABLE_MFE_GUEST_USER_ID,
    ITERABLE_TOP_BANNER_PLACEMENT_ID,
  },
} = getConfig();

// Dettaching this code from the actual bundle
const CaregiverReviews = dynamic(() => import('./CaregiverReviews/CaregiverReviews'), {
  ssr: true,
});
const HowItWorks = dynamic(() => import('./HowItWorks/HowItWorks'), { ssr: true });
const Safety = dynamic(() => import('./Safety/Safety'), { ssr: true });
const MembershipPlan = dynamic(() => import('./MembershipPlan/MembershipPlan'), { ssr: true });
const HomePageFooter = dynamic(() => import('@/components/shared/HomePageFooter'), { ssr: true });
const MemberSelection = dynamic(() => import('@/components/shared/MemberSelection'), {
  ssr: false,
});

// NOTE: with dynamic imports the Component default displayName is not available and Next's generic name "LoadableComponent" is assigned
// For better debugging define a unique display name
CaregiverReviews.displayName = 'LoadableComponent.CaregiverReviews';
HowItWorks.displayName = 'LoadableComponent.HowItWorks';
Safety.displayName = 'LoadableComponent.Safety';
MembershipPlan.displayName = 'LoadableComponent.MembershipPlan';

// Using HOC withLazyLoad to load the component when is on screen
const CaregiverReviewsWithLazyLoad = withLazyLoad(CaregiverReviews);
const HowItWorksWithLazyLoad = withLazyLoad(HowItWorks, 0);
const MembershipPlanWithLazyLoad = withLazyLoad(MembershipPlan, 0);
const SafetyWithLazyLoad = withLazyLoad(Safety);
const FooterWithLazyLoad = withLazyLoad(HomePageFooter);
const MemberSelectionWithLazyLoad = withLazyLoad(MemberSelection);

const LAYOUT = LAYOUTS.rebranding;

function VisitorHomeRebrandLayout() {
  const [selectedVertical, setSelectedVertical] = useState<VerticalSelection>(UNKNOWN_VERTICAL);
  const [openModalOrDrawer, setOpenModalOrDrawer] = useState<boolean>(false);
  const { showPromocodeRibbon, promocodeDiscountMessage } = usePromocodeRibbon();
  const { isDesktop } = useResponsive();
  const { rxData, rxDataDoneLoading } = useRxCodes();
  const {
    adultCareTileTest,
    providerHeader,
    plansPricing,
    seekerAdultCareExperience,
    iterableEmbeddedMessaging,
  } = useTestFlags();
  const getJWTRequest = useOnJwtRequest(ITERABLE_MFE_GUEST_USER_ID);
  const cookies = new Cookies();
  const czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
  const czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);
  const showMonetateBanner = showPromocodeRibbon && !iterableEmbeddedMessaging?.value;

  useEffect(() => {
    const flagsToLog = [
      { flag: CLIENT_FEATURE_FLAGS.GROWTH_VHP_ADULT_CARE_TILE, value: adultCareTileTest },
      { flag: CLIENT_FEATURE_FLAGS.GROWTH_PROVIDER_HEADER, value: providerHeader },
      { flag: CLIENT_FEATURE_FLAGS.PLANS_PRICING, value: plansPricing },
      { flag: CLIENT_FEATURE_FLAGS.SEEKER_ADULT_CARE_EXPERIENCE, value: seekerAdultCareExperience },
    ];

    flagsToLog.forEach(({ flag, value }) => {
      AnalyticsHelper.logTestExposure(flag, value);
    });
  }, [adultCareTileTest, providerHeader, plansPricing, seekerAdultCareExperience]);

  useEffect(() => {
    if (rxDataDoneLoading) {
      AnalyticsHelper.logEvent({
        name: AnalyticsEventName.HOMEPAGE_VIEWED,
        data: {
          slots: [GTM_SLOTS.VHP],
          seo_page_id: '1000',
          screen_name: ScreenName.VISITOR_HOMEPAGE,
          ...rxData,
        },
      });
    }

    logger.info({
      name: AnalyticsEventName.HOMEPAGE_VIEWED,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        layout: LAYOUT,
        czenVisitorId,
        czenSessionId,
      },
    });
  }, [czenSessionId, czenVisitorId, rxData, rxDataDoneLoading]);

  return (
    <IterableEmbeddedProvider
      userId={ITERABLE_MFE_GUEST_USER_ID}
      packageName={ITERABLE_MFE_PACKAGE_NAME}
      apiKey={ITERABLE_MFE_API_KEY}
      placementIds={[ITERABLE_TOP_BANNER_PLACEMENT_ID].map(Number)}
      onJwtRequest={getJWTRequest}
      disabled={!iterableEmbeddedMessaging?.value}>
      <Box data-testid={LAYOUT}>
        {showMonetateBanner && <PromocodeRibbon discountMessage={promocodeDiscountMessage} />}
        <Box
          display="flex"
          justifyContent="center"
          borderBottom={(theme) => `1px solid ${theme.palette.care?.grey[300]}`}>
          <HeaderWithSEOMegaNav layout={LAYOUT} />
        </Box>
        <IterableBanner placementId={ITERABLE_TOP_BANNER_PLACEMENT_ID} />

        <Hero />

        <VerticalSelectionBlock
          setSelectedVertical={setSelectedVertical}
          setOpenModalOrDrawer={setOpenModalOrDrawer}
        />

        {/* Below the fold  */}
        <CaregiverReviewsWithLazyLoad
          setSelectedVertical={setSelectedVertical}
          setOpenModalOrDrawer={setOpenModalOrDrawer}
        />

        <SafetyWithLazyLoad />

        <MembershipPlanWithLazyLoad />

        <HowItWorksWithLazyLoad setOpenModalOrDrawer={setOpenModalOrDrawer} />

        {/* Member selection */}
        <MemberSelectionWithLazyLoad
          selectedVertical={selectedVertical}
          openModalOrDrawer={openModalOrDrawer}
          setOpenModalOrDrawer={setOpenModalOrDrawer}
        />

        <FooterWithLazyLoad renderDesktopView={isDesktop} />
      </Box>
    </IterableEmbeddedProvider>
  );
}

export default VisitorHomeRebrandLayout;
