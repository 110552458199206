import {
  IterableEmbeddedMessageProps,
  IterableEmbeddedSlot,
  IterableEmbeddedRenderers,
} from '@care/iterable-display';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName } from '@/types/enums';

const getIterableAmplitudeData = (message: IterableEmbeddedMessageProps) => ({
  messageId: message.metadata.messageId,
  campaignId: message.metadata.campaignId,
  placementId: message.metadata.placementId,
  campaign: message.payload?.campaign,
  assetLocation: 'VHP',
  assetText: message.elements?.body ?? /* istanbul ignore next */ '',
  assetType: 'banner',
});

const IterableBanner = ({ placementId }: { placementId: number }) => {
  return (
    <IterableEmbeddedSlot
      placementId={Number(placementId)}
      renderer={IterableEmbeddedRenderers.IterableSimpleBanner}
      onRender={(message) => {
        /* istanbul ignore next */
        AnalyticsHelper.logEvent({
          name: AnalyticsEventName.ITERABLE_ASSET_VIEWED,
          data: {
            ...getIterableAmplitudeData(message),
          },
        });
      }}
      onCTAClick={(message) => {
        /* istanbul ignore next */
        AnalyticsHelper.logEvent({
          name: AnalyticsEventName.ITERABLE_ASSET_CLICKED,
          data: {
            ...getIterableAmplitudeData(message),
          },
        });
      }}
    />
  );
};

export default IterableBanner;
